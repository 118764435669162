import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

// Component
import Layout from '../components/layout';
import Seo from '../components/seo';
import HeroContactForm from '../components/hero-contact-form';
// import { PartnerNegative } from '../components/blocks/partner-block';

// Form
import ContactForm from '../forms/contact-form';

const HeroWithPartner = styled(HeroContactForm)`
  & > * {
    padding-bottom: ${(props) => props.offsetBottom + 40}px !important;

    @media (min-width: 1024px) {
      padding-bottom: ${(props) => props.offsetBottom + 64}px !important;
    }
  }
`;

const ContactPage = () => {
  // const [partnerOffset, setPartnerOffset] = useState(0);
  // const PartnerRef = useCallback((node) => {
  //   if (node !== null) {
  //     setPartnerOffset(node.clientHeight);
  //   }
  // }, []);

  return (
    <StaticQuery
      query={graphql`
        query ContactPageQuery {
          allContentfulContactPage(filter: { publishable: { eq: "Publish" } }) {
            nodes {
              title
              seoTitle
              seoDescription
              heading
              description {
                content: description
              }
              partnersTitle
              partners {
                ...partnerFragment
              }
            }
          }
          allContentfulGlobalOption(
            filter: { publishable: { eq: "Publish" } }
          ) {
            nodes {
              address: fullAddress
              phoneNumbers {
                displayName
                value
                countryCode
              }
              facebook
              twitter
              linkedIn
              youtube
            }
          }
        }
      `}
      render={(data) => {
        const { address, phoneNumbers, ...socials } =
          data.allContentfulGlobalOption.nodes[0];
        const params = {
          ...data.allContentfulContactPage.nodes[0],
          contactInfo: {
            address,
            phoneNumbers,
          },
          socials,
        };

        return (
          <Layout>
            <Seo
              title={params?.seoTitle || params.title}
              description={params?.seoDescription || ''}
            />
            <HeroWithPartner
              heading={params.heading}
              description={params.description.content}
              contactInfo={params.contactInfo}
              socials={params.socials}
              // offsetBottom={partnerOffset / 2}
              form={<ContactForm />}
            />
            {/* <PartnerNegative
              ref={PartnerRef}
              title={params.partnersTitle}
              partners={params.partners}
              offsetHeight={partnerOffset / 2}
              className="bg-grey-light"
            /> */}
          </Layout>
        );
      }}
    />
  );
};

export default ContactPage;
