/* global document window */

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// Atoms
import { TextField, MenuItem } from '@mui/material';
import Button from '../atoms/button';

const ContactForm = () => {
  const [state, setState] = useState({
    submitting: false,
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('This is a required field'),
    last_name: Yup.string().required('This is a required field'),
    // full_name: Yup.string().required('This is a required field'),
    emailAddress: Yup.string()
      .required('This is a required field')
      .email('Email must be formatted correctly'),
    enquiryType: Yup.string(),
    message: Yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setState({ submitting: true });

    const form = document.createElement('form');
    form.method = 'POST';
    form.action =
      'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';

    // Your org ID
    const elementOID = document.createElement('input');
    elementOID.name = 'oid';
    elementOID.value = '00D20000000I9ep';
    elementOID.setAttribute('type', 'hidden');
    form.appendChild(elementOID);

    // SFDC redirects to RetURL in the response to the form post
    const elementRetURL = document.createElement('input');
    elementRetURL.name = 'retURL';
    elementRetURL.value = `${window.location.hostname}/thank-you`;
    elementRetURL.setAttribute('type', 'hidden');
    form.appendChild(elementRetURL);

    // These fields are optional debugging elements. Uncomment
    // these lines if you wish to test in debug mode.
    // const elementDebug = document.createElement('input');
    // elementDebug.name = 'debug';
    // elementDebug.value = '1';
    // elementDebug.setAttribute('type', 'hidden');
    // form.appendChild(elementDebug);

    // const elementDebugEmail = document.createElement('input');
    // elementDebugEmail.name = 'debugEmail';
    // elementDebugEmail.value = 'xavier.ang@honestfox.com.au';
    // elementDebugEmail.setAttribute('type', 'hidden');
    // form.appendChild(elementDebugEmail);

    // First name
    const elementFirstName = document.createElement('input');
    elementFirstName.name = 'first_name';
    elementFirstName.value = data.first_name;
    elementFirstName.setAttribute('type', 'hidden');
    form.appendChild(elementFirstName);

    // Last name
    const elementLastName = document.createElement('input');
    elementLastName.name = 'last_name';
    elementLastName.value = data.last_name;
    elementLastName.setAttribute('type', 'hidden');
    form.appendChild(elementLastName);

    // combined name
    // const elementLastName = document.createElement('input');
    // elementLastName.name = 'full_name';
    // elementLastName.value = data.full_name;
    // elementLastName.setAttribute('type', 'hidden');
    // form.appendChild(elementLastName);

    // Email address
    const elementEmailAddress = document.createElement('input');
    elementEmailAddress.name = 'email';
    elementEmailAddress.value = data.emailAddress;
    elementEmailAddress.setAttribute('type', 'hidden');
    form.appendChild(elementEmailAddress);

    // Message
    const elementMessage = document.createElement('input');
    elementMessage.name = '00N4G000006r1sH';
    elementMessage.value = data.message;
    elementMessage.setAttribute('type', 'hidden');
    form.appendChild(elementMessage);

    // Preferrer Contact Method
    const elementContactMethod = document.createElement('input');
    elementContactMethod.name = '00N4G000006r1sM';
    elementContactMethod.value = 'Email';
    elementContactMethod.setAttribute('type', 'hidden');
    form.appendChild(elementContactMethod);

    // Type of Enquiry
    const elementTypeOfEnquiry = document.createElement('input');
    elementTypeOfEnquiry.name = '00N4G000006r1sW';
    elementTypeOfEnquiry.value = data.enquiryType;
    elementTypeOfEnquiry.setAttribute('type', 'hidden');
    form.appendChild(elementTypeOfEnquiry);

    // Lead source
    const elementLeadSource = document.createElement('input');
    elementLeadSource.name = 'lead_source';
    elementLeadSource.value = 'Web form - Enquiry';
    elementLeadSource.setAttribute('type', 'hidden');
    form.appendChild(elementLeadSource);

    document.body.appendChild(form);

    form.submit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="u-body-md mb-4 lg:mb-6">
        Let us know your queries and our team will be in touch with you as soon
        as possible.
      </p>
      <div className="mb-5">
        <Controller
          name="first_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.first_name}
              helperText={errors?.first_name?.message}
              label="First Name*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <div className="mb-5">
        <Controller
          name="last_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.last_name}
              helperText={errors?.last_name?.message}
              label="Last Name*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      {/* <div className="mb-5">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.full_name}
              helperText={errors?.full_name?.message}
              label="Name*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div> */}
      <div className="mb-5">
        <Controller
          name="emailAddress"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.emailAddress}
              helperText={errors?.emailAddress?.message}
              label="Email Address*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <div className="mb-5">
        <Controller
          name="enquiryType"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              select
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    className: 'list-none',
                  },
                },
              }}
              onChange={onChange}
              value={value}
              error={!!errors?.enquiryType}
              helperText={errors?.enquiryType?.message}
              label="Type of enquiry"
              variant="outlined"
              fullWidth
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Sales">Sales</MenuItem>
              <MenuItem value="Support">Support</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          )}
        />
      </div>
      <div className="mb-5">
        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              multiline
              rows={4}
              onChange={onChange}
              value={value}
              error={!!errors?.message}
              helperText={errors?.message?.message}
              label="How can we help you today?"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <Button
        as="button"
        type="submit"
        variant="primary"
        label="Submit"
        disabled={!isValid || state.submitting}
        aria-label="Submit the general contact form"
      />
    </form>
  );
};

export default ContactForm;
