import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import Button from '../atoms/button';

// Components
import { HeroWrapper } from './hero';
import { generateTelFromPhoneNumber } from './helpers';

// Icons
import FacebookIcon from '../icons/icon-facebook.svg';
import TwitterIcon from '../icons/icon-twitter.svg';
import LinkedInIcon from '../icons/icon-linkedin.svg';
import YoutubeIcon from '../icons/icon-youtube.svg';

// Logos
import BgLogo from '../logos/logo-bg.svg';

const propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  contactInfo: PropTypes.shape({
    address: PropTypes.string,
    generalPhone: PropTypes.string,
    supportPhone: PropTypes.string,
  }),
  socials: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    linkedIn: PropTypes.string,
    youtube: PropTypes.string,
  }),
  form: PropTypes.node.isRequired,
};

const defaultProps = {
  description: '',
  contactInfo: {},
  socials: {},
};

const HeroWithForm = ({
  heading,
  description,
  contactInfo,
  socials,
  form,
  ...props
}) => {
  return (
    <HeroWrapper className={cn('relative overflow-hidden', props.className)}>
      <img
        src={BgLogo}
        alt=""
        className="w-[200vw] md:w-[150vw] max-w-[1000px] lg:max-w-[3000px] h-auto absolute top-1/2 left-1/4 transform -translate-y-1/2 -rotate-[120deg] opacity-50 pointer-events-none touch-none mobile+tablet:hidden"
      />
      <div className="relative">
        <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 pt-8 lg:pt-24 pb-10 lg:pb-16">
          <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
            <div className="col-span-12 lg:col-start-2 lg:col-span-4 mobile+tablet:mb-10">
              <h1 className="u-h3 text-white">{heading}</h1>
              {description && (
                <p className="text-white mt-4 lg:mt-8">{description}</p>
              )}
              {contactInfo && (
                <>
                  <h2 className="u-h5 text-white mt-14">Contact information</h2>
                  <p className="text-body-sm text-white mt-2.5 mb-7">
                    {contactInfo.address}
                  </p>
                  {contactInfo.phoneNumbers.length > 0 && (
                    <>
                      {contactInfo.phoneNumbers.map((content, key) => (
                        <p
                          className="text-white mt-1.5"
                          key={`phone-number-${key}`}
                        >
                          {content.displayName}:{' '}
                          <a
                            href={`tel:${generateTelFromPhoneNumber(content)}`}
                            className="underline hover:no-underline"
                            aria-label={`Call for ${content.displayName}`}
                          >
                            {content.value}
                          </a>
                        </p>
                      ))}
                    </>
                  )}
                </>
              )}
              {(socials.facebook ||
                socials.twitter ||
                socials.linkedIn ||
                socials.youtube) && (
                <>
                  <h2 className="u-h5 text-white mt-12">Follow us</h2>
                  <ul className="list-none text-none flex justify-start items-center space-x-4 mt-3 lg:mt-5 lg:ml-auto">
                    {socials.facebook && (
                      <li>
                        <Button
                          as="a"
                          variant="social-white"
                          href={socials.facebook}
                          aria-label="Navigating to Rev's facebook page"
                          newTab
                          icon={
                            <FacebookIcon className="fill-current w-9 h-9" />
                          }
                        />
                      </li>
                    )}
                    {socials.twitter && (
                      <li>
                        <Button
                          as="a"
                          variant="social-white"
                          href={socials.twitter}
                          aria-label="Navigating to Rev's twitter page"
                          newTab
                          icon={
                            <TwitterIcon className="fill-current w-9 h-9" />
                          }
                        />
                      </li>
                    )}
                    {socials.linkedIn && (
                      <li>
                        <Button
                          as="a"
                          variant="social-white"
                          href={socials.linkedIn}
                          aria-label="Navigating to Rev's linkedIn page"
                          newTab
                          icon={
                            <LinkedInIcon className="fill-current w-9 h-9" />
                          }
                        />
                      </li>
                    )}
                    {socials.youtube && (
                      <li>
                        <Button
                          as="a"
                          variant="social-white"
                          href={socials.youtube}
                          aria-label="Navigating to Rev's youtube page"
                          newTab
                          icon={
                            <YoutubeIcon className="fill-current w-9 h-9" />
                          }
                        />
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
            {form && (
              <div className="col-span-12 lg:col-start-7 lg:col-span-5">
                <div className="bg-white rounded p-4 lg:p-8 text-grey-font-1">
                  {form}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </HeroWrapper>
  );
};

HeroWithForm.propTypes = propTypes;
HeroWithForm.defaultProps = defaultProps;

export default HeroWithForm;
